import React, {Suspense}  from 'react';
import logotype from '../resources/logotype.svg';

import { css, Grid, Div, Spinner, Reset } from "@bdfx/fukit"
import ctrans from "../common/trans"
import history from "../common/history"
import LanguageSwitcher from "./app-components/language_switcher"
import { Router, Route, Switch } from "react-router-dom"
import Homepage from "./homepage"
import {initGA} from "../common/tracking"

export const Placeholder = () => <Div minHeight="120vh" background="white" padding="20vh" textAlign="center"><Spinner size={40} color="#ddd" type="bubbles" /></Div>

export const Lazyload = ({ loader, loading = null }) => {
    const Component = React.lazy(loader)
    return (props) => <Suspense fallback={<Placeholder />}>
                        <Component {...props} />
                    </Suspense>
}

const Result = Lazyload({
  loader: () => import(/* webpackChunkName: "result" */ './result'),
});

const ResultDetail = Lazyload({
  loader: () => import(/* webpackChunkName: "result_detail" */ './result-detail'),
});


const Conditions = Lazyload({
  loader: () => process.env.LANG === "sk" ? import(/* webpackChunkName: "conditions" */ './conditions/sk') : import(/* webpackChunkName: "conditions" */ './conditions/cz'),
});

const PageNotFound = Lazyload({
  loader: () => import(/* webpackChunkName: "not_found" */ './app-components/not-found'),
});

const Gdpr = Lazyload({
  loader: () => process.env.LANG === "sk" ? import(/* webpackChunkName: "gdpr" */ './gdpr/sk') : import(/* webpackChunkName: "gdpr" */ './gdpr/cz'),
});

interface Props {
  lang: string | undefined
}

function App({lang}: Props) {

  initGA(lang === "sk" ? 'UA-193195320-2' :'UA-193195320-1')

  const currentLang = process.env.LANG;
  const publicUrl = process.env.PUBLIC_URL;
  const pricingBlogLink = currentLang === "sk" ? `https://pricing-fox.sk/blog-o-automatickom-pricingu?utm_source=audit_pricing&utm_medium=app` : `https://pricing-fox.cz/blog?utm_source=audit_pricing&utm_medium=app`;
  const footerContactLink = "https://pricing-fox.cz/kontakt?utm_source=audit_pricing&utm_medium=app";
  const termsConditionsRouteSegment = currentLang === "sk" ? `/podmienky/` : `/podminky/`

  const handleLinkClick = (target: string) =>(e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push(target);
    return false;
  }

  return (
    <Reset>
        <Header className="header">
            <div>
                <LanguageSwitcher />

                <Grid cols="auto 1fr">
                    <a onClick={handleLinkClick("/")} href={`${publicUrl}/`}>
                        <img width="300" height="60" src={logotype} alt="Logo" />
                    </a>
                    <Menu className="menu">
                        <ul>
                            <li>
                                <a onClick={handleLinkClick("/ukazka_auditu/")} href={`${publicUrl}/ukazka_auditu/`}>
                                    {ctrans("example")}
                                </a>
                            </li>
                            <li><a href={`https://pricing-fox.${currentLang}`} target="_blank">Pricing Fox</a></li>
                            <li><a href={`https://pricing-fox.${currentLang}/kontakt`} target="_blank">{ctrans("contact")}</a></li>
                            <li><a href={`https://pricing-fox.${currentLang}/pricing-audit`} target="_blank">{ctrans("about_audit")}</a></li>
                        </ul>
                    </Menu>
                </Grid>
            </div>
        </Header>

        <section>
            <Router history={history}>
                <Switch>
                    <Route path={`/audit/:result_id/:detail`} component={ResultDetail} />
                    <Route path={`/audit/:result_id`} component={Result} />

                    <Route path={`/ukazka_auditu/:detail`} component={ResultDetail} />
                    <Route path={`/ukazka_auditu/`} component={Result} />

                    <Route path={termsConditionsRouteSegment} component={Conditions} />
                    <Route path={`/gdpr`} component={Gdpr} />
                    <Route path="/" exact={true} component={Homepage}/>

                    <Route path="*" component={PageNotFound}/>
                </Switch>
            </Router>
        </section>

        <Footer className="footer">
            <Div>
                {ctrans("footer_pf_info")}
                <hr/>
                {ctrans("footer_legal_info")}
                <br/><br/>
                <a onClick={handleLinkClick(termsConditionsRouteSegment)} href={termsConditionsRouteSegment}>{ctrans("conditions")}</a>
                &nbsp;|&nbsp;
                <a onClick={handleLinkClick(`/gdpr`)} href={`/gdpr`}>GDPR</a>
                &nbsp;|&nbsp;
                <a href={pricingBlogLink} target="_blank">Pricing BLOG</a>
                &nbsp;|&nbsp;
                <a href={footerContactLink} target="_blank">{ctrans("contact")}</a>
            </Div>
        </Footer>
    </Reset>
  );
}
const Header = css("header")({
  boxShadow: "0 0 15px 0 rgb(0 0 0 / 50%)",
  position: "relative",
  zIndex: 60,
  background: "white",

  "> div": {
    maxWidth: "1100px",
    margin: "auto",
    padding: "3em 1em 1em",
  },
  "img": {
    maxWidth: "300px",
  },

  "@media only screen and (max-width: 1000px)": {
    ".fuk-grid": {
      display: "block",
      textAlign: "center",
    },
    ".menu": {
      textAlign: "center",
    },
  },
})

const Footer = css("footer")({
    background: "#3C3C3C",
    color: "white",
    padding: "50px",
    position: "relative",
    zIndex: -1,
    fontSize: "1.2em",
    paddingTop: "calc(100% + 50px)",
    marginTop: "-100%",
    "a. a:focus, a:visited, a:active": {
      color: "white",
      textDecoration: "underline",
    },
    "hr": {
      maxWidth: "600px",
      margin: "40px auto",
    },
    "a:hover": {
      color: "#93C74F",
    },
    ".activate-button": {
      margin: "1em",
      display: "block",
    },
})

const Menu = css("div")({
  fontSize: "1.2em",
  fontWeight: 300,
  textAlign: "right",
  marginTop: "15px",
  marginBottom: "-10px",
  "li": {
    display: "inline-block",
    paddingLeft: "0.75em",
    ":after": {
      content: "'|'",
      paddingLeft: "0.75em",
    },
    ":last-child:after": {
      content: "''",
    }
  },
  "a, a:visited, a:focus, a:active": {
    color: "#555",
  }
})

export default App;